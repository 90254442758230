import React from 'react'
import styled from '@emotion/styled'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;

  @media (max-width: 768px) {
    > :first-of-type {
      order: ${({ reverseMobile }) => (reverseMobile ? '1' : '0')};
    }
  }

  @media (min-width: 768px) {
    gap: 50px;
    grid-template-columns: ${({ leftWidth, rightWidth }) =>
      `${leftWidth}fr ${rightWidth}fr`};
  }
`

const LeftColumn = styled.div`
  @media (min-width: 768px) {
    text-align: ${({ leftColumnAlign }) => leftColumnAlign || 'left'};
  }
`

const RightColumn = styled.div``

function ResponsiveGrid({
  classes,
  leftWidth = 1,
  rightWidth = 3,
  leftContent,
  rightContent,
  leftColumnAlign,
  rightColumnAlign,
  reverseMobile,
}) {
  return (
    <GridContainer
      reverseMobile={reverseMobile}
      leftWidth={leftWidth}
      rightWidth={rightWidth}
      className={classes}
    >
      <LeftColumn leftColumnAlign={leftColumnAlign}>{leftContent}</LeftColumn>
      <RightColumn rightColumnAlign={rightColumnAlign}>
        {rightContent}
      </RightColumn>
    </GridContainer>
  )
}

export default ResponsiveGrid
