/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Column,
  Columns,
  Image,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import bannerBlueGradient from '../../images/backgrounds/banner-blue-gradient.jpg'
import TwoColumn from '../../components/GridLayouts/TwoColumn'
import { Layout } from '@leshen/gatsby-theme-contentful'
import createImage from '../../utils/createImage'
import header from '../../components/Header'
import footer from '../../components/Footer'
import footerData from '../../data/footerData'
import headerData from '../../data/headerData'
import SeventyThree from '../../images/illustrations/seventy-three.svg'
import SixtyFive from '../../images/illustrations/sixty-five.svg'
import FortyNine from '../../images/illustrations/forty-nine.svg'
import Eighty from '../../images/illustrations/eighty-percent.svg'
import GoodBad from '../../images/illustrations/good-bad-experiences.svg'
import Bridge from '../../images/illustrations/bridge.svg'
import NarrowBanner from '../../components/NarrowBanner'

const Home = () => {
  const SeventyThreeIcon = createImage([SeventyThree], 99, 99)
  const SixtyFiveIcon = createImage([SixtyFive], 99, 99)
  const FortyNineIcon = createImage([FortyNine], 99, 99)
  const EightyIcon = createImage([Eighty], 99, 99)
  const goodBadExp = createImage([GoodBad], 772, 461)
  const BridgeIcon = createImage([Bridge], 530, 530)
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'SocialX | Clearlink',
          description: 'SocialX is a social media management platform.',
          canonical: 'www.clearlink.com/socialx/case-study/',
          robots: 'follow,index',
        },
        path: '/socialx/case-study',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'SocialX | Case Study',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <SplitContent
          backgroundColor="primary"
          image={
            <StaticImage
              src="../../images/people/birds-eye-team-meeting.png"
              alt="A birds eye view of Clearlinkers gathered in a meeting"
              placeholder="blurred"
            />
          }
          mobileImage={
            <StaticImage
              src="../../images/people/birds-eye-team-meeting.png"
              alt="A birds eye view of Clearlinkers gathered in a meeting"
              placeholder="blurred"
              layout="fixed"
              width={250}
              height={250}
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h1">A Brand Love Story: </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                How to Evolve Your Social Media Strategy to Put Customers First
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                Sparks fly when customer care and marketing come together in one
                social CX strategy. It’s no secret that social media plays a
                critical role in how consumers regard, choose, interact with and
                remain loyal to brands. They’re paying attention to your social
                presence (or absence), and what they see in your customer
                interactions counts toward how they feel about your brand.
                Social media strategy has evolved beyond advertising and has
                become a huge component of many brands’ customer service
                operations. But it doesn’t stop there.
              </Typography>
            </div>
          }
        />
        <VariableContent
          // mainContent
          alignMainContent="center"
        >
          <Stack spacing="l">
            <Typography
              variant="h2"
              style={{ margin: '0 auto 50px', textAlign: 'center' }}
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              The State of Social CX
            </Typography>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              className="narrow center"
            >
              <Typography style={{ margin: '0 auto 16px' }}>
                We’re in the midst of an exciting shift in the relationship
                between consumers and the brands they choose. We’ve reached the
                point where the overall customer experience (CX) means more to
                consumers than price or even product. This means that every
                stage of the customer journey — awareness, consideration,
                conversion, support, loyalty and advocacy — affects the
                brand/customer relationship. And because the majority of
                interactions occur through digital touchpoints, social media is
                sitting front and center on what is often a very public stage.
              </Typography>
            </div>
            <Columns mobileCarousel>
              <Column image={<Image data={SeventyThreeIcon} maxWidth={200} />}>
                <Typography variant="h4">On average:</Typography>
                <Typography>
                  73% of consumers worldwide say CX is an important factor in
                  their purchase decisions
                </Typography>
              </Column>
              <Column image={<Image data={SixtyFiveIcon} />}>
                <Typography variant="h4">and:</Typography>
                <Typography>
                  65% of U.S. customers say a positive brand experience is more
                  influential than great advertising
                </Typography>
              </Column>
              <Column image={<Image data={FortyNineIcon} />}>
                <Typography variant="h4">but:</Typography>
                <Typography>
                  Only 49% of U.S. consumers feel that brands are getting it
                  right
                </Typography>
              </Column>
            </Columns>
            <Typography
              variant="legal"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              Only 49% of U.S. consumers feel that brands are getting it right
            </Typography>
            <Typography className="narrow center">
              What you do — or don’t do — on social media can make or break
              relationships with current and potential customers. Which is why
              it’s so critical to develop this space as a shared frontier for
              both your marketing and customer support departments.
            </Typography>
          </Stack>
        </VariableContent>
        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography
                variant="h2"
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                Customer Service <span className="strike-through">and</span>{' '}
                <span className="color-primary">IS</span> Marketing
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack spacing="l">
            <TwoColumn
              leftWidth={1}
              rightWidth={4}
              classes="narrow center"
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Image data={EightyIcon} />
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography>
                    The past decade of digital evolution has proven that
                    ignoring customer complaints on social media damages a
                    brand’s reputation. Nearly 80% of consumers who complained
                    online about a brand or asked a question through social
                    media had their complaints and questions ignored.
                  </Typography>
                </div>
              }
            />
          </Stack>
          <div
            className="narrow medium center"
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography>
              So, it makes sense that many companies have shifted their response
              strategy priorities to increase customer support. But when it
              comes to optimizing interactions across the entire CX, “replying
              to resolve” isn’t going to be enough. Yes, it’s still important to
              respond to customer complaints, but that’s only one side of the
              conversation about your brand that’s happening on social media.{' '}
              <strong>
                By proactively connecting one-on-one with consumers who are
                organically sharing neutral and positive sentiments about your
                brand, you foster those relationships as well, instead of just
                putting out fires
              </strong>
              .
            </Typography>
            <Typography>
              This proactive, organic approach to social engagement builds that
              bridge between marketing and customer support, enabling an
              improved CX at multiple touchpoints on the journey — all in a
              space that customers often turn to first. But how do you evolve
              your strategy to achieve all this?
            </Typography>
          </div>
        </VariableContent>
        <VariableContent alignMainContent="center">
          <div className="narrow center">
            <Typography
              variant="h2"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
              style={{ textAlign: 'center' }}
            >
              Where to Start
            </Typography>
            <Typography
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Begin by listening. To everything. Monitoring customer feedback
              and brand mentions on social media is crucial to identifying
              opportunities to make connections. But don’t just listen for
              negative feedback and support requests. Keep an eye on posts and
              mentions where customers are organically discussing your product,
              comparing your brand to others, or just appreciating your brand in
              general. Reinforce those relationships by engaging directly with
              the poster and acknowledging that they are important to you. 
            </Typography>
            <Typography
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              A positive experience with a brand can be just as impactful as a
              negative one, and social media magnifies the reach of both. When a
              customer engages a brand on social media, they aren’t the only
              ones paying attention to the response (or lack thereof). Followers
              on both sides, yours and your customers’, can see everything that
              happens next. So why not take advantage of that attention to
              strengthen your relationship with the poster while inspiring new
              connections with those who are watching? 
            </Typography>
            <Typography
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Personal, positive interactions from a brand let customers know
              they’re being heard and appreciated — and they LOVE that.
            </Typography>
            <div
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Image data={goodBadExp} />
            </div>
          </div>
        </VariableContent>
        <VariableContent alignMainContent="center" backgroundColor="light">
          <Stack spacing="l">
            <Typography
              variant="h2"
              style={{ margin: '0 auto 50px', textAlign: 'center' }}
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Moving the Needle
            </Typography>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              className="narrow center"
            >
              <Typography style={{ margin: '0 auto 32px' }}>
                At Clearlink, we’ve considered this idea (extensively) and have
                developed proactive and positive social engagement solutions for
                our brand partners, including one of today’s most well-known
                global technology companies. After the first year of the
                program, the brand, which specializes in internet-related
                services and products, experienced the following results:
              </Typography>
            </div>
            <Columns leftAligned={false}>
              <Column backgroundColor="white">
                <Typography variant="h4" className="color-dark">
                  +24 pts.
                </Typography>
                <Typography className="color-dark">
                  Net promoter score
                </Typography>
              </Column>
              <Column backgroundColor="white">
                <Typography variant="h4" className="color-dark">
                  +28%
                </Typography>
                <Typography className="color-dark">Impressions</Typography>
              </Column>
              <Column backgroundColor="white">
                <Typography variant="h4" className="color-dark">
                  +81%
                </Typography>
                <Typography className="color-dark">Engagement</Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          image={<Image data={BridgeIcon} />}
          mainContent={
            <div
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">The Bottom Line</Typography>
              <Typography style={{ marginBottom: '26px' }}>
                Poorly managed social media marketing and support is SO 2010.
                Today, consumers don’t just expect brands to have a social media
                presence, they expect that presence to be valuable. Bridge the
                gap between addressing public customer grievances and fostering
                positive consumer connections by employing a social media
                strategy that focuses on both.
              </Typography>
              <Typography>
                Going beyond “mitigate and resolve” to include a strategy that
                will “surprise and delight” on social media will make loyal
                brand advocates out of your customers, followers and your
                customers’ followers. And by giving both your customer service
                and marketing teams the opportunity to engage in this space, you
                improve the CX at multiple touchpoints of the journey,
                increasing customer lifetime value.
              </Typography>
            </div>
          }
        />
        <NarrowBanner
          backgroundImage={bannerBlueGradient}
          heading={
            <Typography
              variant="h2"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Partner with Clearlink Social
            </Typography>
          }
          button={
            <LinkButton
              to="/socialx/contact"
              color="pink"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Connect With Us
            </LinkButton>
          }
        />
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
